import React, { useEffect, useState } from 'react'
import { ICustomModalProps } from './types'
import { useApp, useWidth } from '@wap-client/core'
import Anchor from '@/components/base/anchor/Anchor'
import { queryService } from '@wap-client/services'
import moment from 'moment'
import Image from '@/components/base/image'

const CustomModal: React.FC<ICustomModalProps> = ({
  mobileImage,
  desktopImage,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const app = useApp()
  const width = useWidth()

  const checkGlobalEventsLenght = async () => {
    try {
      const params = new URLSearchParams()
      const date = new Date()
      params.append('startDate', moment(date).format('YYYY-MM-DDTHH:mm:ss'))
      params.append('pageSize', '1')

      const res = await queryService.run<Array<any>>(app.environment, {
        name: 'get-app-kty-uluslararasi-etkinlikler',
        language: app.language,
        query: params.toString(),
      })

      if (res?.meta?.totalData && res?.meta?.totalData > 0) {
        return true
      } else return false
    } catch (err) {
      return true
    }
  }

  useEffect(() => {
    const lastSeen = localStorage.getItem('customModalLastSeen')
    const now = new Date().getTime()
    const remainingTime = 1000 * 60 // Ne kadar süre aralığında bir modal gösterilsin. (1000 * 60) = 1 dakika

    if (!lastSeen || now - parseInt(lastSeen) > remainingTime) {
      checkGlobalEventsLenght().then((res) => {
        if (res) {
          setIsOpen(true)
          localStorage.setItem('customModalLastSeen', now.toString())
        }
      })
    }

    checkGlobalEventsLenght()
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('open-custom-modal')
    } else {
      document.body.classList.remove('open-custom-modal')
    }

    return () => {
      document.body.classList.remove('open-custom-modal')
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <div className="custom-modal">
      <div className="custom-modal-backdrop" onClick={() => setIsOpen(false)}>
        <svg
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          enableBackground="new 0 0 512 512"
          className="custom-modal-close-svg"
        >
          <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
        </svg>
      </div>
      <div className="custom-modal-in">
        <div className="banner-layout">
          <Anchor
            {...app.settings.routes['globalEvents']}
            onClick={() => setIsOpen(false)}
          >
            {width < 768 ? (
              <Image {...mobileImage} alt={app.page.title} />
            ) : (
              <Image {...desktopImage} alt={app.page.title} />
            )}
          </Anchor>
        </div>
      </div>
    </div>
  )
}

export default CustomModal
