import React from 'react'
import { UIFestivalCalendar } from './types'

const FestivalCalendar:React.FC<UIFestivalCalendar> = ({ festivals = [] }) => {
  return (
    <div className='festival-calendar'>
      {festivals.map((festival, index) => (
        <div className='festival-calendar-item' key={index}>
          <div className="festival-calendar-item-title" style={{ color: festival.color, background: `linear-gradient(290deg, ${festival.endGradient} 0%, ${festival.startGradient} 100%)` }}>
            {festival.title}
          </div>
          <div className="festival-calendar-item-date">
            {festival.date}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FestivalCalendar