import React, { useCallback, useEffect, useState } from 'react'

import moment from 'moment'
import 'moment/locale/tr'

import Icon from '@/components/base/icon'

import { functions, useApp, useWidth } from '@wap-client/core'
import { queryService } from '@wap-client/services'

import { SoonFestival, SoonFestivalsProps } from './types'
import Anchor from '@/components/base/anchor'

const SoonFestivals: React.FunctionComponent<SoonFestivalsProps> = () => {
  const app = useApp()
  const width = useWidth()

  const date = moment().format('YYYY-MM-DD[T]00:00:00')

  moment.locale(app.language.substring(0, 2))

  const [festivals, setFestivals] = useState<SoonFestival[]>([])
  const [filterFestival, setFilterFestival] = useState<SoonFestival[]>([])

  const getFestivals = useCallback(async (language?: any) => {
    try {
      const response = await queryService.run<SoonFestival[]>(
        app.environment,
        {
          name: 'kty-festivaller-soon-veri-sorgusu',
          language: language,
        },
        [
          {
            name: 'id',
            path: 'id',
            type: 'static',
          },
          {
            name: 'title',
            path: 'baslik',
            type: 'static',
          },
          {
            name: 'startDate',
            path: 'baslangicTarihi',
            type: 'static',
          },
          {
            name: 'endDate',
            path: 'bitisTarihi',
            type: 'static',
          },
          {
            name: 'festivalLink',
            path: 'festivalLink',
            type: 'static',
          },
        ]
      )

      if (response) {
        setFestivals(response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getFestivals(app.language)
  }, [app, app.language])

  const nowFestival = (festivals: SoonFestival[]) => {
    const now: SoonFestival[] = []
    const feture: SoonFestival[] = []

    festivals.map((item: SoonFestival) => {
      if (item.startDate <= date && item.endDate >= date) {
        now.push(item)
      }

      if (item.startDate > date) {
        feture.push(item)
      }
    })

    setFilterFestival([...now, ...feture])
  }

  useEffect(() => {
    nowFestival(festivals)
  }, [festivals])

  return (
    <div className="soon-festivals">
      {filterFestival.slice(0, width > 1025 ? 2 : 1).map((item, index) => {
        const start = moment(item.startDate)
        const end = moment(item.endDate)

        return (
          <Anchor
            href={`${item.festivalLink.href}?festivalId=${item.id}`}
            className={functions.classnames(
              'festival',
              item.startDate <= date &&
                item.endDate >= date &&
                'festival--active'
            )}
            key={index}
          >
            <div className="festival__title">{item.title}</div>
            <div className="festival__date">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${start.format('DD MMMM')} - ${end.format(
                    'DD MMMM'
                  )}`,
                }}
              />
            </div>
            <div className="festival__days-left">
              {item.startDate <= date && item.endDate >= date
                ? 'Aktif'
                : `${Math.abs(moment(date).diff(start, 'days'))} ${
                    app.settings.translations['leftDay']
                  }`}
              <Icon name="arrow-right" size={'xsmall'} />
            </div>
            <div className="festival__soon-icon">
              {item.startDate <= date && item.endDate >= date ? (
                <Icon name="today" size={'medium'} />
              ) : (
                <Icon name="clock" size={'large'} />
              )}
            </div>
          </Anchor>
        )
      })}
    </div>
  )




}

export default SoonFestivals
